import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { Container, Row, Col } from "react-grid-system"
import { CustomLink } from "./utils"

const Footer = () => (
  <Wrapper>
    <Container>
      <Row>
        <Col>
          <Section>
            <p>
              <strong>Multi-Consult Srl</strong> - Iscrizione CCIAA di Bergamo
              R.E.A. N 278809 / Capitale Sociale Euro 10.200,00 / P. IVA - C.F.
              02303740167
            </p>
            <div>
              <CustomLink
                href="https://www.multi-consult.com/privacy-policy/"
                target="_blank"
              >
                Privacy Policy
              </CustomLink>{" "}
              -{" "}
              <CustomLink
                href="https://www.multi-consult.com/cookie-policy/"
                target="_blank"
              >
                Cookie Policy
              </CustomLink>
            </div>
          </Section>
        </Col>
      </Row>
    </Container>
  </Wrapper>
)
const Wrapper = styled.footer`
  margin-top: 6rem;
`

const Section = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${DesignTokens.spacing[5]};
  border: 1px solid ${DesignTokens.colors.primary[500]};
  border-bottom: none;
  font-size: ${DesignTokens.fontSizes[2]};
  div {
    min-width: 20%;
    text-align: left;
  }
  p {
    margin-bottom: ${DesignTokens.spacing[3]};
  }
  @media screen and (min-width: 769px) {
    flex-direction: row;
    div {
      text-align: right;
    }
    p {
      margin-bottom: 0;
    }
  }
`

export default Footer
