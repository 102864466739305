import chroma from "chroma-js"

const primary = "#00A487"
const secondary = "#000000"
const tertiary = "#ffffff"
const white = "#ffffff"
const black = "#000000"
const primaryScale = chroma.scale([white, primary, black])
const secondaryScale = chroma.scale([white, secondary, black])
const tertiaryScale = chroma.scale([white, tertiary, black])

const DesignTokens = {
  colors: {
    white: white,
    black: black,
    overlay: {
      image:
        "linear-gradient(to top, rgba(0, 0, 0, 0.55) 8%, rgba(0, 0, 0, 0) 72%)",
      light: "linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))",
      dark: "linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6))",
      primary:
        "linear-gradient(0deg, rgba(0, 168, 137, 0.8), rgba(0, 168, 137, 0.8))",
    },
    primary: {
      100: primaryScale(0.1),
      200: primaryScale(0.2),
      300: primaryScale(0.3),
      400: primaryScale(0.4),
      500: primaryScale(0.5),
      600: primaryScale(0.6),
      700: primaryScale(0.7),
      800: primaryScale(0.8),
      900: primaryScale(0.9),
    },
    secondary: {
      100: secondaryScale(0.1),
      200: secondaryScale(0.2),
      300: secondaryScale(0.3),
      400: secondaryScale(0.4),
      500: secondaryScale(0.5),
      600: secondaryScale(0.6),
      700: secondaryScale(0.7),
      800: secondaryScale(0.8),
      900: secondaryScale(0.9),
    },
    tertiary: {
      100: tertiaryScale(0.1),
      200: tertiaryScale(0.2),
      300: tertiaryScale(0.3),
      400: tertiaryScale(0.4),
      500: tertiaryScale(0.5),
      600: tertiaryScale(0.6),
      700: tertiaryScale(0.7),
      800: tertiaryScale(0.8),
      900: tertiaryScale(0.9),
    },
  },
  borderRadius: ["0.1rem"],
  spacing: [
    "0rem",
    "0.25rem",
    "0.5rem",
    "0.75rem",
    "1rem",
    "1.25rem",
    "1.5rem",
    "2rem",
    "2.5rem",
    "3rem",
    "4rem",
    "6rem",
  ],
  fontSizes: [
    "0.5rem",
    "0.65rem",
    "0.75rem",
    "0.875rem",
    "1rem",
    "1.25rem",
    "1.5rem",
    "2rem",
    "2.5rem",
    "3rem",
    "4rem",
    "6rem",
  ],
  shadows: {
    raised:
      "rgba(46, 41, 51, 0.08) 0px 1px 2px, rgba(71, 63, 79, 0.08) 0px 2px 4px;",
    floating:
      "rgba(46, 41, 51, 0.08) 0px 2px 4px, rgba(71, 63, 79, 0.16) 0px 4px 8px;",
    overlay:
      "rgba(46, 41, 51, 0.08) 0px 4px 8px, rgba(71, 63, 79, 0.16) 0px 8px 16px;",
    dialog:
      "rgba(46, 41, 51, 0.08) 0px 4px 16px, rgba(71, 63, 79, 0.16) 0px 8px 24px;",
  },
}

export default DesignTokens
