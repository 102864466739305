import React from "react"
import Helmet from "react-helmet"
import { setConfiguration } from "react-grid-system"
import Footer from "./footer"

const Layout = ({ children }) => {
  setConfiguration({
    breakpoints: [576, 768, 800, 1200, 1300],
    containerWidths: [540, 740, 960, 1140, 1340]
  });

  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;900&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      {children}
      <Footer />
    </>
  )
}

export default Layout
